import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Quote.css";
function Quote() {
  const [quote, setQuote] = useState({});
  const getQuote = () => {
    axios.get(`https://api.quotable.io/random`).then((response) => {
      setQuote(response.data);
    });
  };
  const randomQuote = () => {
    getQuote();
  };
  useEffect(() => {
    getQuote();
  }, []);

  const Loader = () => {
    return (<div className="lds-dual-ring"></div>)
  }
  const Container = () => {
    return (
      <div className="container">
        <h2 className="quote">{quote.content}</h2>
        <p className="author">{quote.author}</p>
        <button className="generate_button" onClick={randomQuote}>
          <i className="fas fa-redo"></i>
        </button>
      </div>
    )
  }
  return (
    quote.content ? <Container /> : <Loader />

  );
}

export default Quote;
