import "./App.css";
import Quote from "./Components/Quote/Quote";

function App() {
  return (
    <div className="App">
      <Quote />
    </div>
  );
}

export default App;
